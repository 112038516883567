.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;
  min-width: 0;
  min-height: 0;
}
.loginForm:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.section {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  align-self: stretch;
  width: 100%;
  min-width: 0;
}
.wrapper {
  box-shadow: 0px 2px 12px -2px #0f2a411a, 0px 1px 4px -2px #0f2a410f;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-grow: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 0;
  min-height: 0;
  padding: 64px;
}
.wrapper > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.wrapper > :global(.__wab_flex-container) > *,
.wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper > :global(.__wab_flex-container) > picture > img,
.wrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .wrapper {
    height: 100%;
    min-height: 0;
    padding: 32px 32px 0px;
  }
}
@media (max-width: 1024px) {
  .wrapper > :global(.__wab_flex-container) {
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 1024px) {
  .wrapper > :global(.__wab_flex-container) > *,
  .wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .wrapper > :global(.__wab_flex-container) > picture > img,
  .wrapper
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper {
    padding: 32px 32px 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper > :global(.__wab_flex-container) > *,
  .wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .wrapper > :global(.__wab_flex-container) > picture > img,
  .wrapper
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.heading {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  min-width: 0;
}
.heading > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
}
@media (max-width: 576px) {
  .heading > :global(.__wab_flex-container) {
    margin-left: calc(0px - 12px);
    width: calc(100% + 12px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 576px) {
  .heading > :global(.__wab_flex-container) > *,
  .heading > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .heading > :global(.__wab_flex-container) > picture > img,
  .heading
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 12px;
    margin-top: 0px;
  }
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
}
@media (max-width: 1024px) {
  .logo {
    display: flex;
  }
}
.img__m64Y8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 205px;
  height: 36px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__m64Y8 > picture > img {
  object-fit: cover;
}
@media (max-width: 576px) {
  .img__m64Y8 {
    width: 180px;
    height: 32px;
    flex-shrink: 0;
  }
}
.link {
  position: relative;
  width: 100%;
  text-align: right;
  min-width: 0;
}
@media (max-width: 1024px) {
  .link {
    align-self: flex-start;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .link {
    align-self: flex-start;
  }
}
.link___5Pv45 {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  width: 100%;
  min-width: 0;
}
.form {
  width: 512px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.form > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.form > :global(.__wab_flex-container) > *,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.form > :global(.__wab_flex-container) > picture > img,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .form {
    width: 100%;
    min-width: 0;
    padding: 64px 0px 96px;
  }
}
@media (max-width: 1024px) {
  .form > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .form {
    width: 100%;
    min-width: 0;
    padding: 64px 0px 96px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .form > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (max-width: 576px) {
  .form {
    padding-top: 68px;
  }
}
.header {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding: 0px 16px;
}
.header > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.header > :global(.__wab_flex-container) > *,
.header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.header > :global(.__wab_flex-container) > picture > img,
.header
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (max-width: 1024px) {
  .header {
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .header {
    padding: 0px;
  }
}
.title {
  font-family: var(--rubik);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: var(--token-vF1ggEL4NG72);
  display: block;
  align-self: stretch;
  position: relative;
}
.desc {
  font-family: var(--roboto);
  font-weight: 300;
  font-size: 19px;
  line-height: 28px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.notice:global(.__wab_instance) {
  position: relative;
}
.bottom {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 0px 16px;
}
.bottom > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.bottom > :global(.__wab_flex-container) > *,
.bottom > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bottom > :global(.__wab_flex-container) > picture > img,
.bottom
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .bottom {
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .bottom {
    padding: 0px;
  }
}
.usernameProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.usernameWrapper {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.usernameWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.usernameWrapper > :global(.__wab_flex-container) > *,
.usernameWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.usernameWrapper > :global(.__wab_flex-container) > picture > img,
.usernameWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.label {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.onChangeProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.usernameInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__u8Fqz {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.passwordProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.password {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.password > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.password > :global(.__wab_flex-container) > *,
.password > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.password > :global(.__wab_flex-container) > picture > img,
.password
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.passwordLabels {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.passwordLabels > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.passwordLabels > :global(.__wab_flex-container) > *,
.passwordLabels > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.passwordLabels > :global(.__wab_flex-container) > picture > img,
.passwordLabels
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.label7 {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.label9 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-pkN2y4iq-viG);
  display: block;
  align-self: stretch;
  position: relative;
  text-align: right;
}
.passwordInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sMgbk {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.logInBtn:global(.__wab_instance) {
  height: 48px;
  position: relative;
  width: 128px;
  flex-shrink: 0;
}
@media (max-width: 1024px) {
  .logInBtn:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .logInBtn:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.svg__c3Szl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--token-9T9NC2lhv1Ej);
  user-select: none;
}
.spinnerIcon:global(.__wab_instance) {
  max-width: 100%;
  width: 26px;
  height: 26px;
}
.svg___3R9Dl {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.loginFormSecurity:global(.__wab_instance) {
  position: relative;
}
.loginFormResources:global(.__wab_instance) {
  position: relative;
  align-self: center;
}
.line {
  width: 100%;
  height: 310px;
  display: block;
  position: absolute;
  overflow: hidden;
  left: 0px;
  top: 620px;
  min-width: 0;
  flex-shrink: 0;
}
.img__uwgCm {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  left: 0px;
  top: 0px;
}
.img__uwgCm > picture > img {
  object-fit: cover;
}
