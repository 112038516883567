.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(rgba(249, 250, 251, 1), rgba(249, 250, 251, 1)),
    url("https://site-assets.plasmic.app/3648a9660ccc75f47048e2740948dc57.jpg")
      0% 0% / 325px 325px repeat,
    rgba(255, 255, 255, 1);
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.root > * {
  grid-column: 4;
}
@media (max-width: 1024px) {
  .root > * {
    grid-column: 4;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.isAutoSave:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.isAutoLogin:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.desktop {
  min-height: 100vh;
  box-shadow: 12px 0px 14px -14px #0f2a411a;
  justify-self: center;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(249, 250, 251, 1), rgba(249, 250, 251, 1)),
    url("https://site-assets.plasmic.app/3648a9660ccc75f47048e2740948dc57.jpg")
      0% 0% / 325px 325px repeat,
    rgba(255, 255, 255, 1);
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.content {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  background: linear-gradient(rgba(249, 250, 251, 1), rgba(249, 250, 251, 1)),
    url("https://site-assets.plasmic.app/3648a9660ccc75f47048e2740948dc57.jpg")
      0% 0% / 325px 325px repeat,
    rgba(255, 255, 255, 1);
  box-shadow: 12px 0px 14px -14px #0f2a411a;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
}
.news__brlyr:global(.__wab_instance) {
  position: relative;
  height: 100%;
  align-self: stretch;
  min-height: none;
  width: 480px;
  flex-shrink: 0;
}
.loginForm:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.mobile {
  background: linear-gradient(rgba(249, 250, 251, 1), rgba(249, 250, 251, 1)),
    url("https://site-assets.plasmic.app/3648a9660ccc75f47048e2740948dc57.jpg")
      0% 0% / 325px 325px repeat,
    rgba(255, 255, 255, 1);
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1440px;
  flex-grow: 1;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
}
@media (max-width: 1024px) {
  .mobile {
    display: flex;
  }
}
.loginForm2:global(.__wab_instance) {
  max-width: 100%;
  height: auto;
}
.news__hQebs:global(.__wab_instance) {
  position: relative;
  height: auto;
  align-self: stretch;
  width: 100%;
  min-width: 0;
}
.loginTroubleshootingModal:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.contactModal:global(.__wab_instance) {
  max-width: 100%;
}
