.alert {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: var(--token-F-isEiSy-7QO);
  position: relative;
  min-width: 0;
  border-radius: 8px;
  padding: 10px 16px;
}
.alert > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.alert > :global(.__wab_flex-container) > *,
.alert > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.alert > :global(.__wab_flex-container) > picture > img,
.alert > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 12px;
}
.iconWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  height: 20px;
  padding: 2px 0px;
}
.icon {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-pkN2y4iq-viG);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.text {
  font-family: var(--rubik);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--token-pkN2y4iq-viG);
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
}
